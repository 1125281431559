import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

export const IconLinkedin = ({ className }: { className?: string }) => {
  return (
    <div className={clsx("w-24 md:w-20", className)}>
      <img src="/assets/img/common/linkedin.png" alt="" width={23.52} height={20} />
    </div>
  );
};

export const IconGithub = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <FontAwesomeIcon icon={faGithub} className="text-19" />
    </div>
  );
};

export const IconWiki = ({ className }: { className?: string }) => {
  return (
    <div className={clsx("bg-navy px-6 pt-3 pb-4 md:p-4", className)}>
      <span className="block w-28 md:w-26">
        <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 27 10">
          <path d="M12.356.796h-2.47l.78.78-1.91 6.201-1.834-6.2.78-.78h-3.08l.805.805.312 1.014L4.14 7.777l-1.833-6.2.78-.78H.007l.805.805L3.412 10h.858l1.898-5.98L8.028 10h.857l2.665-8.398.806-.806ZM15.488 10l-.728-.728V3.227l-2.106.767.702.702v4.576l-.728.728h2.86Zm-1.43-7.735a.727.727 0 0 0 .728-.728.738.738 0 0 0-.728-.74.738.738 0 0 0-.728.74c0 .403.325.728.728.728ZM23.198 10l-3.575-3.575 3.03-3.029h-2.406l.702.702-2.626 2.626V.12l-2.106.702.702.702v7.748l-.727.728h2.86l-.729-.728V6.893l2.431 2.431V10h2.444Zm3.157 0-.727-.728V3.227l-2.107.767.703.702v4.576l-.729.728h2.86Zm-1.43-7.735a.727.727 0 0 0 .729-.728.738.738 0 0 0-.729-.74.738.738 0 0 0-.727.74c0 .403.325.728.727.728Z" />
        </svg>
      </span>
    </div>
  );
};

export const IconLink = ({ className }: { className?: string }) => {
  return (
    <div className={clsx("bg-navy py-1 px-2", className)}>
      <p className="font-varela text-13 leading-none tracking-[-0.02em] text-white">LINK</p>
    </div>
  );
};

export const IconPdf = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 19 22">
      <path
        fillRule="evenodd"
        d="M3 21h12.333a2 2 0 0 0 2-2V6.27L12.267 1H3a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2Zm9.692-21H3a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h12.333a3 3 0 0 0 3-3V5.867L12.693 0Z"
        clipRule="evenodd"
      />
      <path d="M15.146 13.04c-.88-.914-3.282-.542-3.857-.474-.846-.812-1.42-1.793-1.624-2.132.305-.913.508-1.827.542-2.808 0-.846-.339-1.76-1.286-1.76-.338 0-.643.204-.812.474-.406.71-.237 2.132.406 3.587-.372 1.049-.71 2.064-1.658 3.857-.981.406-3.045 1.353-3.214 2.368-.068.305.034.61.27.846a1.3 1.3 0 0 0 .846.304c1.252 0 2.47-1.725 3.316-3.18.71-.237 1.827-.575 2.944-.778 1.319 1.15 2.47 1.32 3.078 1.32.812 0 1.117-.339 1.218-.643.17-.339.068-.71-.169-.982Zm-.846.575c-.034.236-.338.473-.88.338a4.641 4.641 0 0 1-1.725-.88c.44-.067 1.421-.169 2.132-.034.27.068.541.237.473.576Zm-5.65-6.97c.068-.102.17-.17.27-.17.305 0 .373.373.373.677a7.435 7.435 0 0 1-.406 2.098c-.507-1.353-.406-2.3-.237-2.605Zm-.067 6.564c.27-.542.642-1.49.778-1.895a8.626 8.626 0 0 0 1.082 1.387c0 .034-1.048.237-1.86.508Zm-1.997 1.353c-.778 1.286-1.59 2.098-2.03 2.098-.067 0-.135-.034-.203-.068-.101-.068-.135-.17-.101-.305.101-.473.981-1.116 2.334-1.725Z" />
    </svg>
  );
};

export const IconExternal = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
      <path
        fillRule="evenodd"
        d="M10.556 4.184c-1.193-.013-2.247-.422-2.802-.977l-.707.707c.79.79 2.126 1.256 3.499 1.27 1.387.014 2.897-.43 4.033-1.566l-.707-.707c-.908.908-2.138 1.285-3.316 1.273Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M13.603 7.23c.012 1.193.421 2.247.976 2.802l-.707.707c-.79-.79-1.255-2.126-1.27-3.498-.013-1.387.43-2.897 1.567-4.034l.707.707c-.908.908-1.285 2.138-1.273 3.316Z"
        clipRule="evenodd"
      />
      <path fillRule="evenodd" d="m5 12 8.4-8.4.707.707-8.4 8.4L5 12Z" clipRule="evenodd" />
      <path fillRule="evenodd" d="M6 7H1v10h10v-5h1v6H0V6h6v1Z" clipRule="evenodd" />
    </svg>
  );
};
