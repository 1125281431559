import { graphql, PageProps } from "gatsby";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";
import { IconExternal, IconPdf } from "../components/commons/Icon";
import Layout from "../components/Layout";
import { INews } from "../types";

const NewsPost = ({ data }: PageProps<Queries.NewsPostPageQuery>) => {
  const { t } = useI18next();
  const news = data.news as INews;

  return (
    <>
      <Helmet>
        <title>{news.title}</title>
        <meta property="og:title" content={news.title} />
        <meta name="twitter:title" content={news.title} />
      </Helmet>
      <Layout>
        <main className="mx-auto px-25 py-100 text-navy md:w-760 md:px-0 md:pt-160 md:pb-230">
          <time
            dateTime={news.publishedDate}
            className="font-varela text-16 leading-none tracking-[-0.02em] md:text-14"
          >
            {news.publishedDate}
          </time>
          <h1 className="mt-10 text-20 font-medium leading-[1.8] md:text-26">{news.title}</h1>
          <div className="mt-40 w-full">
            <img
              src={`${news.thumbnail.url}?w=760&fm=webp&dpr=2`}
              className="mx-auto"
              alt=""
              width={news.thumbnail.width}
              height={news.thumbnail.height}
            />
          </div>
          <div className="news-body mt-40" dangerouslySetInnerHTML={{ __html: news.body }} />
          {news.summary?.map((summary, i) => (
            <dl key={i} className="[&:nth-of-type(n+2)]:mt-40">
              <dt className="whitespace-pre-wrap text-14 font-medium leading-[1.8] tracking-[-0.02em]">
                {summary.title}
              </dt>
              <dd>
                <a
                  href={summary.link}
                  target="_blank"
                  rel="noreferrer"
                  className="mt-7 inline-block font-condensed text-18 leading-none underline md:text-16"
                >
                  {summary.link}
                </a>
              </dd>
            </dl>
          ))}
          {news.pdfLink && (
            <div className="mt-40">
              <a href={news.pdfLink.url} target="_blank" className="inline-flex items-center" rel="noreferrer">
                <p className="text-14 font-medium leading-[1.8]">{news.pdfLink.title}</p>
                <div className="ml-7 w-18">
                  <IconPdf />
                </div>
              </a>
            </div>
          )}
          {news.quoteLink && (
            <div className="mt-40">
              <a href={news.quoteLink} target="_blank" className="inline-flex items-center" rel="noreferrer">
                <p className="font-condensed text-18 leading-none">{news.quoteLink}</p>
                <div className="ml-7 w-18 translate-y-[-2px]">
                  <IconExternal />
                </div>
              </a>
            </div>
          )}
          <div className="mt-40 text-center md:mt-80">
            <Link
              to="/news/"
              // className="stroke-text font-varela text-24 leading-none"
              className="group relative m-auto flex h-50 w-237 items-center justify-end"
            >
              <span className="btn-link w-202">News All</span>
              <img
                src="/assets/img/common/btn-arrow.svg"
                className="absolute left-0 h-23 w-50 rotate-180 duration-[0.2s] group-hover:left-[-1rem]"
                width="50"
                height="23"
                alt="More Detail"
              />
            </Link>
          </div>
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query NewsPostPage($id: String!, $language: String!) {
    locales: allLocale(filter: { ns: { in: ["top", "meta"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    news: news(id: { eq: $id }) {
      publishedDate
      thumbnail {
        url
        width
        height
      }
      title
      body
      summary {
        title
        link
      }
      pdfLink {
        title
        url
      }
      quoteLink
    }
  }
`;

export default NewsPost;
